import React, {useEffect, useState} from "react";
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
    tokens
} from "@fluentui/react-components";
import {Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle} from "@fluentui/react-dialog";
import {useServices} from "../../hooks";
import {useToast} from "../../utils/ToastProvider";
import {SharedLinksListDto} from "../../models/common/statistic/SharedLinksListDto";
import {SharedLinksDto} from "../../models/common";
import {LoadingButton} from "../ButtonWithLoadingState";

interface StatsManageLinkDialogProps {
    isOpen: boolean;
    onClose: () => void;
}

const StatsManageLinkDialog: React.FC<StatsManageLinkDialogProps> = ({isOpen, onClose}) => {
    const {statisticService} = useServices();
    const {showToast} = useToast();
    const [links, setLinks] = useState<SharedLinksDto[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const domain = window.location.origin; // или указать статически, например: const domain = "https://example.com";

    /**
     * Загрузка списка ссылок
     */
    useEffect(() => {
        if (isOpen) {
            setIsLoading(true);
            statisticService.getLinks()
                .then((data: SharedLinksListDto) => {
                    setLinks(data.sharedLinks);
                })
                .catch((error: any) => {
                    console.error(error);
                    showToast("Error!", error.message, "error");
                })
                .finally(() => setIsLoading(false));
        }
    }, [isOpen, statisticService, showToast]);

    /**
     * Обработка удаления ссылки
     * @param id
     */
    const handleDelete = async (id: string) => {
        try {
            await statisticService.deleteLink(id);
            setLinks((prev) => prev.filter((link) => link.id !== id));
            showToast("Success", "Link deleted successfully", "success");
        } catch (error: any) {
            showToast("Error!", error.message, "error");
        }
    }

    return (
        <Dialog
            open={isOpen}
            onOpenChange={(_, data) => {
                if (!data.open) onClose();
            }}
        >
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>Manage Links</DialogTitle>
                    <DialogContent>
                        {isLoading ? (
                            <div>Loading...</div>
                        ) : links.length === 0 ? (
                            <div>No links available</div>
                        ) : (
                            <Table style={{width: '100%', tableLayout: 'auto'}}>
                                <TableHeader>
                                    <TableRow>
                                        <TableHeaderCell>Link</TableHeaderCell>
                                        <TableHeaderCell>Last Use</TableHeaderCell>
                                        <TableHeaderCell>Actions</TableHeaderCell>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {links.map((link) => {
                                        const shareUrl = `${domain}/statistic/share/${link.id}`;
                                        return (
                                            <TableRow key={link.id}>
                                                <TableCell
                                                    style={{
                                                        padding: '8px',
                                                        whiteSpace: 'normal',
                                                        wordBreak: 'break-all'
                                                    }}
                                                >
                                                    <a
                                                        href={shareUrl}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        style={{
                                                            color: 'black',
                                                            textDecoration: 'none',
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        {shareUrl}
                                                    </a>
                                                </TableCell>
                                                <TableCell>{link.lastUse?.toString()}</TableCell>
                                                <TableCell>
                                                    <LoadingButton
                                                        enabled={true}
                                                        style={{background: tokens.colorPaletteDarkOrangeBackground3}}
                                                        appearance="primary"
                                                        buttonText={"Delete"}
                                                        onClick={() => handleDelete(link.id)}/>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button appearance={"primary"} onClick={onClose}>Close</Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};

export default StatsManageLinkDialog;
