import React, {useCallback, useEffect, useState} from "react";
import {
    Input,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
    tokens
} from "@fluentui/react-components";
import {useServices} from "../../hooks";
import {DataBrokerDto} from "../../models/admin/dataBroker";
import {LoadingButton} from "../ButtonWithLoadingState";
import {DeleteRegular} from "@fluentui/react-icons";
import {LoadingState} from "../../models";
import {store} from "../../store";

/**
 * Таблица отображения посредников данных ("прокладок")
 * @constructor
 */
const DataBrokerTable: React.FC = () => {
    const services = useServices();
    const [dataBrokers, setDataBrokers] = useState<DataBrokerDto[]>([]);
    const [addLoadingState, setAddLoadingState] = useState<LoadingState>("initial");
    const [newDataBroker, setNewDataBroker] = useState('');

    const columns = [
        {columnKey: "url", label: "Url"},
        {columnKey: "buildCount", label: "BuildCount"},
        {columnKey: "actions", label: "Actions"},
    ];

    const updateBrokerList = useCallback(async () => {
        try {
            const response = await services.adminDataBrokerService.getList();
            setDataBrokers(response);
        } catch (error: any) {
            console.error(error);
        }
    }, [services.adminDataBrokerService]);

    useEffect(() => {
        (async () => {
            await updateBrokerList()
        })();
    }, [services.adminDataBrokerService, services, updateBrokerList]);

    const addNewBroker = useCallback(async () => {
        try {
            if (isValidUrl(newDataBroker)) {
                store.loaderStore.setLoading(true);
                setAddLoadingState("loading");
                await services.adminDataBrokerService.add(newDataBroker);
                await updateBrokerList();
                setAddLoadingState("loaded");
            } else {
                alert("Provide valid URL!");
            }
        } catch (error: any) {
            setAddLoadingState("error");
            console.error(error);
        } finally {
            store.loaderStore.setLoading(false);
        }
    }, [services, newDataBroker, updateBrokerList]);

    function isValidUrl(urlString: string): boolean {
        try {
            new URL(urlString);
            return true;
        } catch (e) {
            return false;
        }
    }

    const handleDelete = useCallback(async (id: number) => {
        try {
            store.loaderStore.setLoading(true);
            await services.adminDataBrokerService.delete(id);
            await updateBrokerList();
        } catch (error: any) {
            console.error(error);
        } finally {
            store.loaderStore.setLoading(false)
        }
    }, [services.adminDataBrokerService, updateBrokerList]);

    return (
        <div>
            <div style={{margin: '0.3em'}}>
                <Input type={"url"} style={{margin: '0.3em'}} onChange={(_ev, data) => {
                    setNewDataBroker(data.value);
                }}></Input>
                <LoadingButton state={addLoadingState}
                               buttonText={"Add"}
                               appearance={"primary"}
                               enabled={true}
                               onClick={addNewBroker}/>
            </div>
            <Table
                as={"div"}
                role="grid"
                aria-label="Table with grid keyboard navigation"
                style={{minWidth: "620px"}}>
                <TableHeader as={"div"}>
                    <TableRow as={"div"}>
                        {columns.map((column) => (
                            <TableHeaderCell as={"div"} key={column.columnKey}>
                                {column.label}
                            </TableHeaderCell>
                        ))}
                    </TableRow>
                </TableHeader>
                <TableBody as={"div"}>
                    {dataBrokers.map((item) => (
                        <TableRow as={"div"} key={item.id}>
                            <TableCell as={"div"}>
                                {item.uri}
                            </TableCell>
                            <TableCell as={"div"}>
                                {item.buildCount}
                            </TableCell>
                            <TableCell as={"div"}>
                                <LoadingButton style={{color: tokens.colorPaletteDarkOrangeForeground1}}
                                               appearance={"subtle"}
                                               enabled={true}
                                               onClick={() => handleDelete(item.id)}
                                               icon={<DeleteRegular/>}/>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    )
}

/**
 * Имя отображаемое при отладке
 */
DataBrokerTable.displayName = "DataBrokerTable";

export default DataBrokerTable;