import {makeStyles} from "@fluentui/react-components";

export default makeStyles({
    cardContainer: {
        marginTop: "1em",
        minWidth: "80%",
        maxWidth: "80%",
        minHeight: "fit-content",
    },
    form: {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        // Stack the label above the field (with 2px gap per the design system)
        "> div": {display: "flex", flexDirection: "column", gap: "2px"},
    },
    error: {
        color: "red",
        fontSize: "14px",
        marginTop: "10px",
    },
});