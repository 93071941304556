import React, {useCallback, useEffect, useState} from 'react';
import {Button, Input, Label, Select, SelectOnChangeData, Subtitle2,} from '@fluentui/react-components';
import {useServices, useStore} from '../../hooks';
import UseStyle from './BuilderFormStyles';
import {BuilderOptionsDto, BuildRequestStatesDto} from '../../models/common';
import {ErrorCode} from '../../models/shared/ErrorCode';
import {useToast} from "../../utils/ToastProvider";
import {GetMessageByCode} from "../../utils";
import {observer} from "mobx-react-lite";

/**
 * Форма создания запроса на генерацию билда
 * @constructor
 */
const BuilderForm: React.FC = () => {
    const styles = UseStyle();
    const [buildTag, setBuildTag] = useState('');
    const services = useServices();
    const {showToast} = useToast();
    const store = useStore();
    const [dataBroker, setDataBroker] = useState<string[]>([]);
    const [selectedBroker, setSelectedBroker] = useState<string>('');

    /**
     * Получение списка рефералов
     */
    const fetchReferrals = useCallback(async () => {
        try {
            store.builderStore.partners = await services.builderService.getRefList();
        } catch (error: any) {
            const errorMessage =
                error.code === ErrorCode.CustomErrorMessage
                    ? error.message
                    : GetMessageByCode(error.code)

            showToast('Error!', errorMessage, "error");
        }
    }, [services.builderService, showToast, store.builderStore]);

    /**
     * Получение списка брокеров данных
     */
    const fetchDataBroker = useCallback(async () => {
        try {
            setDataBroker(await services.builderService.getDataBrokers());
        } catch (error: any) {
            const errorMessage =
                error.code === ErrorCode.CustomErrorMessage
                    ? error.message
                    : GetMessageByCode(error.code)

            showToast('Error!', errorMessage, "error");
        }
    }, [services.builderService, showToast, setDataBroker]);


    /**
     * Получение списка партнёров
     */
    useEffect(() => {
        (async () => {
            await fetchReferrals();
            await fetchDataBroker();
        })();
    }, [services.builderService, fetchReferrals, fetchDataBroker]);

    /**
     * отправка запроса на генерацию билда
     */
    const handleRequestNewBuild = useCallback(async () => {
        try {
            const body: BuilderOptionsDto = {
                partnerId: store.builderStore.partnerId || '',
                buildTag: buildTag,
                brokerUrl: selectedBroker
            };
            await services.builderService.newRequest(body);
            store.builderStore.requestState.state = BuildRequestStatesDto.Waiting;
        } catch (error: any) {
            const errorMessage =
                error.code === ErrorCode.CustomErrorMessage
                    ? error.message
                    : GetMessageByCode(error.code)

            showToast('Error!', errorMessage, "error");
        }
    }, [buildTag, showToast, services.builderService, store.builderStore, selectedBroker])

    /**
     * Обработчик выбора партнёра
     * @param _ev событие
     * @param data данные
     */
    const partnerChangeHandler = (_ev: any, data: SelectOnChangeData) => {
        store.builderStore.setPartnerId = data.value;
    }

    const dataBrokerChangeHandler = (_ev: any, data: SelectOnChangeData) => {
        setSelectedBroker(data.value);
    }

    /**
     * Вывод списка партнёров при наличии
     */
    const showPartnerChooseInput = () => {
        if (store.builderStore.partners.length > 0) {
            return (
                <div>
                    <Label>
                        Partner
                    </Label>
                    <Select onChange={partnerChangeHandler}>
                        <option key={'no_choose'} value={''}>
                            No partner selected
                        </option>
                        {store.builderStore.partners.map((referral) => (
                            <option key={referral.id} value={referral.id}>
                                {String(referral.userName)}
                            </option>
                        ))}
                    </Select>
                </div>
            );
        }
        return null;
    }

    const showDataBrokerSelect = () => {
        console.log('dataBroker:', dataBroker);
        return (
            <div>
                <Label>
                    Data broker
                </Label>
                <Select onChange={dataBrokerChangeHandler}>
                    <option key={'Automatic'} value={''}>
                        Automatic assignment
                    </option>
                    {Array.isArray(dataBroker) && dataBroker.length > 0 ? (
                        dataBroker.map((brokerUrl, index) => (
                            <option key={index} value={brokerUrl}>
                                {brokerUrl}
                            </option>
                        ))
                    ) : (
                        <option disabled key={'no-brokers'}>
                            No brokers available
                        </option>
                    )}
                </Select>
            </div>
        );
    };

    return (
        <div>
            <Subtitle2>Create New Build</Subtitle2>
            <form className={styles.form}>
                {showPartnerChooseInput()}
                {showDataBrokerSelect()}
                <div>
                    <Label>Build tag</Label>
                    <Input
                        placeholder="Enter build tag"
                        value={buildTag}
                        onChange={(event) => setBuildTag(event.target.value)}
                    />
                </div>
                <Button appearance={"primary"} onClick={handleRequestNewBuild}>Request new build</Button>
            </form>
        </div>
    );
};

BuilderForm.displayName = "BuilderForm";

export default observer(BuilderForm);
