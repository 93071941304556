import {ApiResult} from "../models";
import {BuilderHistoryItemDto, BuilderOptionsDto, BuilderRequestViewDto, UserDto} from "../models/common";
import {AxiosService} from "./axios";
import {BUILDER_URL} from "../shared/constUrl";

export class BuilderService {
    /**
     * Экземпляр axios сервиса
     * @private
     */
    private axios: AxiosService;

    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * получить текущее состояние билдера
     */
    async getBuilderInfo(): Promise<BuilderRequestViewDto> {
        try {
            const resp = await this.axios.get<BuilderRequestViewDto>("/builder");
            return Promise.resolve(resp.data);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * создать запрос на создание нового билда
     */
    async newRequest(body: BuilderOptionsDto): Promise<string> {
        try {
            const resp = await this.axios.post<string>("/builder/new-request", body);
            return Promise.resolve(resp.data);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * получить историю билдов
     */
    async previous(): Promise<BuilderHistoryItemDto[]> {
        try {
            const resp = await this.axios.get<BuilderHistoryItemDto[]>("/builder/previous");
            return Promise.resolve(resp.data);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * удаление неудачной попытки билда
     */
    async deletedBadTask(): Promise<void> {
        try {
            await this.axios.post("/builder/try-again");
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * запрос на скачивание готового билда
     */
    async downloadBuild(body: string): Promise<ApiResult<File>> {
        try {
            const resp = await this.axios.get<File>("/builder/download/" + body);
            return Promise.resolve(resp);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * удаление билда
     */
    async deleteBuild(body: string): Promise<void> {
        try {
            await this.axios.post("/builder/delete/" + body);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * получение списка траферских аккаунтов
     */
    async getRefList(): Promise<UserDto[]> {
        try {
            const resp = await this.axios.get<UserDto[]>("/user/ref/list");
            return Promise.resolve(resp.data);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    async getDataBrokers(): Promise<string[]> {
        try {
            const resp = await this.axios.get<string[]>(BUILDER_URL + '/data-brokers');
            return Promise.resolve(resp.data);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}