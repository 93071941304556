import Log from "../models/common/logs/Log";
import {makeAutoObservable} from "mobx";
import {LogFilteringOptions} from "../models/common/logs/LogFilteringOptions";
import {LogsRequestDto} from "../models/common/logs/LogRequestDto";
import {Paging} from "../models/shared";
import {LogPageDto} from "../models/common/logs/LogPageDto";

/**
 * Хранилище списка логов
 */
export class LogsPageStore {
    /**
     * Конструктор хранилища логов пользователя
     */
    constructor() {
        makeAutoObservable(this);
    }

    private _isFilterOpen: boolean = false;

    public get isFilterOpen() {
        return this._isFilterOpen;
    }

    public set isFilterOpen(isFilterOpen: boolean) {
        this._isFilterOpen = isFilterOpen;
    }

    private _isFilterApplied: boolean = false;

    public get isFilterApplied() {
        return this._isFilterApplied;
    }

    public set isFilterApplied(isFilterApplied: boolean) {
        this._isFilterApplied = isFilterApplied;
    }

    private _endTime: Date | null = null;

    public get endTime(): Date | null {
        return this._endTime;
    }

    public set endTime(time: Date | null) {
        this._endTime = time;
    }

    private _startTime: Date | null = null;

    public get startTime(): Date | null {
        return this._startTime;
    }

    public set startTime(time: Date | null) {
        this._startTime = time;
    }


    /**
     * Логи
     * @private
     */
    private _logs: Log[] = [];

    /**
     * Получить список логов
     */
    public get logs() {
        return this._logs;
    }

    /**
     * Удалить элемент списка
     * @param logId Идентификатор лога
     */
    removeItemById(logId: number) {
        this._logs = this._logs.filter(item => item.id !== logId);
    }

    /**
     * Сохранить список логов
     * @param value
     */
    public set logs(value: Log[]) {
        this._logs = value;
    }

    /**
     * Опции для поиска
     * @private
     */
    private _options: LogFilteringOptions = {
        countryOptions: [],
        tagOptions: [],
        extWalletNames: [],
        pmExtNames: [],
        walletNames: [],
        referralUsers: [],
        buildTags: []
    };

    /**
     * Получить список опций
     */
    public get options() {
        return this._options;
    }

    /**
     * Сохранить список опций
     * * @param value
     */
    public set options(value: LogFilteringOptions) {
        this._options = value;
    }

    /**
     * Параметры для запроса логов
     * @private
     */
    private _params: LogsRequestDto = {
        pageNumber: 1,
        pageSize: 50,
    }

    /**
     * Получить параметры
     */
    public get params() {
        return this._params;
    }

    /**
     * Сохранить параметры фильтрации
     * * @param value
     */
    public set params(value: LogsRequestDto) {
        this._params = value;
    }

    /**
     * Установить значения параметров по умолчанию
     */
    public setDefaultParams() {
        this._params = {
            countryList: [],
            tagsList: [],
            walletsList: [],
            walletExtensionsList: [],
            pm2FaExtensionsList: [],
            cookieKeyword: "",
            passwordKeyword: "",
            partnerList: [],
            startId: 0,
            endId: undefined,
            byNotes: undefined,
            byWallets: undefined,
            byMessenger: undefined,
            byCreditCard: undefined,
            bySoft: undefined,
            byWalletExtensions: undefined,
            startDate: undefined,
            endDate: undefined,
            pageNumber: 0,
            pageSize: this._params.pageSize,
            notDownloaded: undefined,
            ipAddress: undefined,
            buildTagList: undefined,
        }
    }

    /**
     * Состояние пагинации на странице логов
     * @private
     */
    private _paging: Paging = {
        pageNumber: 1,
        pageSize: 50,
        totalItems: 0,
        totalPages: 1
    };

    /**
     * Получить параметры пагинации
     */
    public get paging() {
        return this._paging;
    }

    /**
     * Сохранить параметры пагинации
     * * @param value
     */
    public set paging(value: Paging) {
        this._paging = value;
    }

    /**
     * Количество отмеченных логов
     * @private
     */
    private _selectedLogs: number[] = [];

    public get selectedLogs() {
        return this._selectedLogs;
    }

    public set selectedLogs(value: number[]) {
        this._selectedLogs = value;
    }

    /**
     * ООбновление переменных новыми данными после запроса
     * @param model
     */
    public updateData(model: LogPageDto) {
        this._logs = model.logList;
        this._paging = model.paging;
        this._params = {
            ...this._params,
            pageNumber: (model.paging.pageNumber === 0) ? 1 : model.paging.pageNumber,
            pageSize: model.paging.pageSize
        };
    }
}