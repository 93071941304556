import React, {useCallback, useEffect, useState} from 'react';
import {
    Button,
    Card,
    CardHeader,
    Label,
    Subtitle2,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableRow,
    Text,
    tokens
} from '@fluentui/react-components';
import UseStyle from '../../forms/BuilderForm/BuilderFormStyles';
import {useServices} from '../../hooks';
import {BuilderHistoryItemDto} from '../../models/common';
import {ErrorCode} from '../../models/shared/ErrorCode';
import {useToast} from "../../utils/ToastProvider";
import {GetMessageByCode} from "../../utils";
import {DeleteRegular} from "@fluentui/react-icons";
import {LoadingButton} from "../ButtonWithLoadingState";
import {BUILDER_URL} from "../../shared/constUrl";

/**
 * форма с историей всех билдов
 */

const BuilderHistory: React.FC = () => {
    const styles = UseStyle();
    const [historyItems, setHistoryItems] = useState<BuilderHistoryItemDto[]>([]);
    const services = useServices();
    const {showToast} = useToast();
    /**
     * получение истории с апи
     */
    const fetchHistory = useCallback(async () => {
        try {
            const response = await services.builderService.previous();
            setHistoryItems(response);
        } catch (error: any) {
            const errorMessage =
                error.code === ErrorCode.CustomErrorMessage
                    ? error.message
                    : GetMessageByCode(error.code)

            showToast('Error!', errorMessage, "error");
        }
    }, [services.builderService, setHistoryItems, showToast]);

    useEffect(() => {
        fetchHistory();
    }, [fetchHistory]);

    //кнопка скачивания файла
    const handleDownload = async (requestId: string) => {
        try {
            const urlWithParams = `${BUILDER_URL}/download/${requestId}`;
            await services.fileDownloadService.downloadFile(urlWithParams);
        } catch (error: any) {
            const errorMessage = error.code === ErrorCode.CustomErrorMessage ? error.message : GetMessageByCode(error.code);
            showToast('Failed to fetch builder info'
                , errorMessage
                , "error");
        }
    };
//кнопка удаления файла
    const handleDelete = async (requestId: string) => {
        try {
            await services.builderService.deleteBuild(requestId);
            await fetchHistory();
        } catch (error: any) {
            const errorMessage =
                error.code === ErrorCode.CustomErrorMessage
                    ? error.message
                    : GetMessageByCode(error.code)

            showToast('Error!', errorMessage, "error");
        }
    };

    return (
        <Card className={styles.cardContainer}>
            <CardHeader header={<Subtitle2>Build History</Subtitle2>}/>

            <Table style={{margin: 0, padding: 0}}>
                <TableHeader>
                    <TableRow>
                        <TableCell><Label>Request ID</Label></TableCell>
                        <TableCell><Label>Done At</Label></TableCell>
                        <TableCell><Label>File Name</Label></TableCell>
                        <TableCell><Label>Action</Label></TableCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {historyItems.length > 0 ? (
                        historyItems.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell><Text>{item.requestId}</Text></TableCell>
                                <TableCell><Text>{item.doneAt}</Text></TableCell>
                                <TableCell><Text>{item.fileName}</Text></TableCell>
                                <TableCell>
                                    <Button appearance={"primary"} onClick={() => handleDownload(item.requestId)}
                                            style={{marginRight: '8px'}}> Download </Button>

                                    <LoadingButton aria-label="Delete"
                                                   style={{color: tokens.colorPaletteDarkOrangeForeground1}}
                                                   appearance={"subtle"}
                                                   onClick={() => handleDelete(item.requestId)}
                                                   enabled={true}
                                                   icon={<DeleteRegular/>}/>
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={4}><Text>No history available.</Text></TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Card>
    );
};

export default BuilderHistory;
