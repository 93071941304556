import React, {useCallback, useEffect, useState} from 'react';
import {Card, Spinner, Title3} from '@fluentui/react-components';
import UseStyle from './StatsLogDataStyles';
import {Doughnut} from 'react-chartjs-2';
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from 'chart.js';
import {useServices, useStore} from "../../hooks";
import {observer} from "mobx-react-lite";
import {StatsQueryDto} from "../../models/common";
import {useToast} from "../../utils/ToastProvider";
import {StatsLogDataProps} from "./StatsLogDataProps";
import {StatisticsQuerySharedDto} from "../../models/common/statistic/StatsQuerySharedDto";

ChartJS.register(ArcElement, Tooltip, Legend);

/**
 * форма отображения статистики
 */
const StatsLogData: React.FC<StatsLogDataProps> = (props) => {

    const [loading, setLoading] = useState(false);
    const styles = UseStyle();
    const {statisticSharedStore, statisticsStore} = useStore();
    const {statisticService, statisticSharedService} = useServices();
    const {showToast} = useToast();

    /**
     * Получение статистики
     */
    const fetchData = useCallback(async () => {
        if (props.shared) {
            statisticSharedStore.data = null;
        } else {
            statisticsStore.data = null;
        }

        try {
            setLoading(true);
            if (props.shared) {
                const body: StatisticsQuerySharedDto = {
                    startDate: statisticSharedStore.startDate,
                    endDate: statisticSharedStore.endDate,
                };

                const [statisticResult] = await Promise.all([
                    statisticSharedService.getSharedStatsData(
                        statisticSharedStore.id,
                        body
                    ),
                ]);

                statisticSharedStore.data = statisticResult;
                statisticSharedStore.startDate = new Date(statisticResult.startStatistic);
            } else {
                const body: StatsQueryDto = {
                    startDate: statisticsStore.startDate,
                    endDate: statisticsStore.endDate,
                    partnerName: statisticsStore.partnerName,
                    buildTag: statisticsStore.buildTag,
                };

                const [statisticResult] = await Promise.all([
                    statisticService.getStatisticData(body),
                ]);

                statisticsStore.data = statisticResult;
                statisticsStore.startDate = new Date(statisticResult.startStatistic);
            }
        } catch (error: any) {
            showToast('Error!', error.message, 'error');
        } finally {
            setLoading(false);
        }
    }, [statisticService, statisticsStore, statisticSharedService, statisticSharedStore, props.shared, showToast]);

    useEffect(() => {
        fetchData();
    }, [fetchData, props.shared]);

    const chartData = React.useMemo(() => {
        const sourceData = props.shared ? statisticSharedStore : statisticsStore;

        if (!sourceData.data || sourceData.data.totalCount === 0) {
            return {
                labels: ['Empty', 'Important', 'Normal'],
                datasets: [
                    {
                        label: 'Statistics',
                        data: [0, 0, 0],
                        backgroundColor: ['#0078d4', '#ff8c00', '#00bcf2'],
                        borderWidth: 1,
                    },
                ],
            };
        }

        const {emptyCount = 0, importantCount = 0, totalCount = 1} = sourceData.data;
        const normalCount = totalCount - emptyCount - importantCount;

        const emptyPercentage = ((emptyCount / totalCount) * 100).toFixed(1);
        const importantPercentage = ((importantCount / totalCount) * 100).toFixed(1);
        const normalPercentage = ((normalCount / totalCount) * 100).toFixed(1);

        return {
            labels: [
                `Empty (${emptyPercentage}%)`,
                `Important (${importantPercentage}%)`,
                `Normal (${normalPercentage}%)`,
            ],
            datasets: [
                {
                    label: 'Statistics',
                    data: [emptyCount, importantCount, normalCount],
                    backgroundColor: ['#0078d4', '#ff8c00', '#00bcf2'],
                    borderWidth: 1,
                },
            ],
        };
    }, [props.shared, statisticsStore, statisticSharedStore, statisticsStore.data, statisticSharedStore.data]);

    const chartOptions = {
        maintainAspectRatio: false,
        plugins: {
            tooltip: {
                callbacks: {
                    label: (tooltipItem: any) => {
                        const value = tooltipItem.raw;
                        const total = props.shared
                            ? statisticSharedStore.data?.totalCount || 1
                            : statisticsStore.data?.totalCount || 1;
                        const percentage = ((value / total) * 100).toFixed(1);
                        return `${tooltipItem.label}: ${value} (${percentage}%)`;
                    },
                },
            },

            datalabels: {
                display: true,
                formatter: () =>
                    `Total logs: ${
                        props.shared
                            ? statisticSharedStore.data?.totalCount || 0
                            : statisticsStore.data?.totalCount || 0
                    }`,
                color: '#000',
                font: {
                    size: 16,
                    weight: 'bold',
                },
            },
        },
    };

    return (
        <Card className={styles.cardContainer}>
            {loading ? (
                <Spinner/>
            ) : (
                <>
                    {(props.shared
                        ? statisticSharedStore.data
                        : statisticsStore.data) && (
                        <div className={styles.logData}>
                            <Title3>Log data statistics</Title3>
                            {((props.shared
                                ? statisticSharedStore.data?.totalCount
                                : statisticsStore.data?.totalCount) || 0) === 0 ? (
                                <div className={styles.noDataMessage}>
                                    No data available for the selected period.
                                </div>
                            ) : (
                                <>
                                    <div className={styles.diagram}>
                                        <Doughnut data={chartData} options={chartOptions}/>
                                    </div>
                                    <div className={styles.totalLogs}>
                                        Total: {props.shared
                                        ? statisticSharedStore.data?.totalCount || 0
                                        : statisticsStore.data?.totalCount || 0}
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </>
            )}
        </Card>
    );
};

/**
 * Имя отображаемое при отладке
 */
StatsLogData.displayName = 'StatsLogData';
export default observer(StatsLogData);