import React, {useCallback, useEffect, useState} from "react";
import {Layout} from "../../../containers/Layout";
import {Button, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow} from "@fluentui/react-components";
import {JsonProfilePageDto} from "../../../models/admin";
import {useServices} from "../../../hooks";
import {useToast} from "../../../utils/ToastProvider";
import {Open20Regular} from '@fluentui/react-icons';
import {ADMIN_JSON_PROFILE_OPEN_URL} from "../../../shared/constUrl";
import {useNavigate} from "react-router-dom";

/**
 * Страница управления профилями пользователей
 * @constructor
 */
const JsonProfilesPage: React.FC = () => {
    /**
     * Именования столбцов таблицы
     */
    const columns = [
        {columnKey: "userId", label: "User ID"},
        {columnKey: "userName", label: "Username"},
        {columnKey: "version", label: "Version"},
        {columnKey: "uploadTime", label: "Uploaded At"},
        {columnKey: "actions", label: "Actions"},
    ];

    /**
     * Значения по умолчанию
     */
    const defaults: JsonProfilePageDto = {
        profiles: [],
        paging: {
            pageNumber: 1,
            pageSize: 50,
            totalPages: 1,
            totalItems: 0
        }
    };

    const [profilesDto, setProfilesDto] = useState<JsonProfilePageDto>(defaults);
    const services = useServices();
    const {showToast} = useToast();
    const navigate = useNavigate();

    /**
     * запрос и загрузка списка профилей
     */
    const updateJsonProfilesList = useCallback(async () => {
        try {
            const response = await services.adminJsonProfilesService.getList();
            setProfilesDto(response);
        } catch (error: any) {
            showToast("Error!", error, "error");
        }
    }, [services, showToast]);

    /**
     * Эффект загрузки профилей
     */
    useEffect(() => {
        updateJsonProfilesList().then(() => {
        });
    }, [updateJsonProfilesList]);

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>, userId: string) => {
        const file = event.target.files?.[0];
        if (!file) {
            alert('Выберите файл для загрузки');
            return;
        }

        const confirm = window.confirm(`Вы уверены, что хотите загрузить файл для пользователя ${userId}?`);
        if (!confirm) {
            return;
        }

        const formData = new FormData();
        formData.append('file', file)

        try {
            await services.adminJsonProfilesService.uploadFile(formData, userId);
            alert('Файл успешно загружен');
            await updateJsonProfilesList(); // Обновляем список после успешной загрузки
        } catch (error: any) {
            showToast("Error!", error, "error");
        }
    };

    return (
        <Layout pageTittle={"JSON Profiles"}>
            <div>
                <Table as={"div"}
                       role="grid"
                       aria-label="Table with grid keyboard navigation"
                       style={{minWidth: "620px"}}>

                    <TableHeader as={"div"}>
                        <TableRow as={"div"}>
                            {columns.map((column) => (
                                <TableHeaderCell as={"div"} key={column.columnKey}>
                                    {column.label}
                                </TableHeaderCell>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody as={"div"}>
                        {profilesDto.profiles.map((item) => (
                            <TableRow as={"div"} key={item.userId}>
                                <TableCell as={"div"}>{item.userId}</TableCell>
                                <TableCell as={"div"}>{item.userName}</TableCell>
                                <TableCell as={"div"}>{item.version}</TableCell>
                                <TableCell as={"div"}>{item.updatedAt}</TableCell>
                                <TableCell as={"div"}>
                                    <div style={{display: "flex", alignItems: "center", gap: "8px"}}>
                                        <Button icon={<Open20Regular/>} onClick={() => {
                                            navigate(ADMIN_JSON_PROFILE_OPEN_URL + '/' + item.userId);
                                        }}/>
                                        <input
                                            title="file"
                                            type="file"
                                            onChange={(event) => handleFileUpload(event, item.userId)}
                                        />
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </Layout>
    );
};

/**
 * Имя отображаемое при отладке
 */
JsonProfilesPage.displayName = "JsonProfilePage";

export default JsonProfilesPage;